export const PERMISSION_CODE = {
  // NEW FOR KMS
  SYSTEM: {
    KMS_FULL_CONTROL: 'KMS_FULL_CONTROL',
  },
  ACCOUNTS: {
    KMS_ACCOUNTS_VIEW: 'KMS_ACCOUNTS_VIEW',
    KMS_ACCOUNTS_CREATE: 'KMS_ACCOUNTS_CREATE',
    KMS_ACCOUNTS_UPDATE: 'KMS_ACCOUNTS_UPDATE',
    KMS_ACCOUNTS_DELETE: 'KMS_ACCOUNTS_DELETE',
    KMS_ACCOUNTS_LOCK: 'KMS_ACCOUNTS_LOCK',
    KMS_ACCOUNTS_VIEW_DETAIL: 'KMS_ACCOUNTS_VIEW_DETAIL',
    KMS_ACCOUNTS_RESET_PASSWORD: 'KMS_ACCOUNTS_RESET_PASSWORD',
  },
  EMPLOYEES: {
    KMS_EMPLOYEES_VIEW: 'KMS_EMPLOYEES_VIEW',
    KMS_EMPLOYEES_CREATE: 'KMS_EMPLOYEES_CREATE',
    KMS_EMPLOYEES_UPDATE: 'KMS_EMPLOYEES_UPDATE',
    KMS_EMPLOYEES_DELETE: 'KMS_EMPLOYEES_DELETE',
    KMS_EMPLOYEES_VIEW_DETAIL: 'KMS_EMPLOYEES_VIEW_DETAIL',
    KMS_EMPLOYEES_IMPORT: 'KMS_EMPLOYEES_IMPORT',
    KMS_EMPLOYEES_EXPORT_TEMPLATE: 'KMS_EMPLOYEES_EXPORT_TEMPLATE',
  },
  EVALUATIONS: {
    KMS_EVALUATIONS_VIEW: 'KMS_EVALUATIONS_VIEW',
    KMS_EVALUATIONS_CREATE: 'KMS_EVALUATIONS_CREATE',
    KMS_EVALUATIONS_UPDATE: 'KMS_EVALUATIONS_UPDATE',
    KMS_EVALUATIONS_DELETE: 'KMS_EVALUATIONS_DELETE',
    KMS_EVALUATIONS_EXPORT: 'KMS_EVALUATIONS_EXPORT',
    KMS_EVALUATIONS_SEND_EMAIL: 'KMS_EVALUATIONS_SEND_EMAIL',
    KMS_EVALUATIONS_VIEW_DETAIL: 'KMS_EVALUATIONS_VIEW_DETAIL',
    KMS_EVALUATIONS_GENERATE_BY_DEPARTMENT: 'KMS_EVALUATIONS_GENERATE_BY_DEPARTMENT',
    KMS_EVALUATIONS_GENERATE_BY_EMPLOYEE: 'KMS_EVALUATIONS_GENERATE_BY_EMPLOYEE',
    KMS_EVALUATIONS_UPDATE_POINT_BY_STAFF: 'KMS_EVALUATIONS_UPDATE_POINT_BY_STAFF',
    KMS_EVALUATIONS_UPDATE_POINT_BY_LINE_LEADER: 'KMS_EVALUATIONS_UPDATE_POINT_BY_LINE_LEADER',
    KMS_EVALUATIONS_UPDATE_POINT_BY_PROCESS_LEADER:
      'KMS_EVALUATIONS_UPDATE_POINT_BY_PROCESS_LEADER',
  },
  PROFILE: {
    KMS_PROFILE_VIEW: 'KMS_PROFILE_VIEW',
    KMS_PROFILE_CHANGE_PASSWORD: 'KMS_PROFILE_CHANGE_PASSWORD',
    KMS_PROFILE_UPDATE: 'KMS_PROFILE_UPDATE',
  },
  ROLES: {
    KMS_ROLES_CREATE: 'KMS_ROLES_CREATE',
    KMS_ROLES_UPDATE: 'KMS_ROLES_UPDATE',
    KMS_ROLES_DELETE: 'KMS_ROLES_DELETE',
    KMS_ROLES_VIEW: 'KMS_ROLES_VIEW',
    KMS_ROLES_VIEW_DETAIL: 'KMS_ROLES_VIEW_DETAIL',
    KMS_ROLES_CHANGE_ROLE_USER: 'KMS_ROLES_CHANGE_ROLE_USER',
  },
  TASKS: {
    KMS_TASKS_VIEW: 'KMS_TASKS_VIEW',
    KMS_TASKS_CREATE: 'KMS_TASKS_CREATE',
    KMS_TASKS_UPDATE: 'KMS_TASKS_UPDATE',
    KMS_TASKS_DELETE: 'KMS_TASKS_DELETE',
    KMS_TASKS_EXPORT_TEMPLATE: 'KMS_TASKS_EXPORT_TEMPLATE',
    KMS_TASKS_VIEW_DETAIL: 'KMS_TASKS_VIEW_DETAIL',
    KMS_TASKS_IMPORT: 'KMS_TASKS_IMPORT',
  },
  DEPARTMENTS: {
    KMS_DEPARTMENTS_VIEW: 'KMS_DEPARTMENTS_VIEW',
    KMS_DEPARTMENTS_CREATE: 'KMS_DEPARTMENTS_CREATE',
    KMS_DEPARTMENTS_UPDATE: 'KMS_DEPARTMENTS_UPDATE',
    KMS_DEPARTMENTS_DELETE: 'KMS_DEPARTMENTS_DELETE',
    KMS_DEPARTMENTS_VIEW_DETAIL: 'KMS_DEPARTMENTS_VIEW_DETAIL',
  },
};

export const ROLE_CODE = {
  SUPERADMIN: {
    code: 'SUPERADMIN',
    name: 'SuperAdmin',
    description: 'Super Admin',
    isActive: true,
  },
};
