import { PERMISSION_CODE } from 'src/app/@common/app.permission.constants';
import { ROUTERS } from 'src/app/@common/core.constants';

export const navItems = [
  // {
  //   title: true,
  //   name: 'Quản lý người dùng',
  //   attributes: {
  //     permissions: [PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL],
  //   },
  // },
  {
    name: 'Công việc',
    url: ROUTERS.ADMIN.TASKS,
    icon: 'assets/img/svg/icon-task.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL, PERMISSION_CODE.TASKS.KMS_TASKS_VIEW],
    },
  },
  {
    name: 'Đánh giá',
    url: ROUTERS.ADMIN.EVALUATIONS,
    icon: 'assets/img/svg/icon-evaluation.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL,
        PERMISSION_CODE.EVALUATIONS.KMS_EVALUATIONS_VIEW,
      ],
    },
  },
  {
    name: 'Nhân viên',
    url: ROUTERS.ADMIN.EMPLOYEES,
    icon: 'assets/img/svg/icon-employee-group.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL,
        PERMISSION_CODE.EMPLOYEES.KMS_EMPLOYEES_VIEW,
      ],
    },
  },
  {
    name: 'Phòng ban',
    url: ROUTERS.ADMIN.DEPARTMENTS,
    icon: 'assets/img/svg/icon-nav-02.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL],
    },
  },
  {
    name: 'Tài khoản',
    url: ROUTERS.ADMIN.ACCOUNTS,
    icon: 'assets/img/svg/icon-nav-03.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL,
        PERMISSION_CODE.ACCOUNTS.KMS_ACCOUNTS_VIEW,
      ],
    },
  },
  {
    name: 'Phân quyền',
    url: ROUTERS.ADMIN.ROLES,
    icon: 'assets/img/svg/icon-nav-04.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL, PERMISSION_CODE.ROLES.KMS_ROLES_VIEW],
    },
  },
  // {
  //   name: 'Cấu hình',
  //   url: ROUTERS.ADMIN.CONFIG,
  //   icon: 'assets/img/svg/icon-nav-06.svg',
  //   attributes: {
  //     permissions: [PERMISSION_CODE.SYSTEM.KMS_FULL_CONTROL],
  //   },
  // },
];
